import styled from "styled-components";
import { ContentBox, SoilBar, UsdcBar } from "../../../components";
import { config } from "../../../config";
import { useMediaQuery } from "../../../hooks";
import { device } from "../../../style";
import { formatBigNumber, humanizePoolDuration } from "../../../utils";
import { BoostInfo } from "./BoostInfo";
import * as S from "./PoolData.styled";
import { ActionsWrapper } from "../Pool.styled";
import { PoolDeposit } from "../PoolDeposit";
import { PoolActions } from "../PoolActions/PoolActions";
import { smallHorizontalLine } from "../../../assets";
import { PoolProgress } from "../PoolProgress";
import { useTokenData } from "../../../hooks/useTokenData";

type InfoSectionProps = {
  poolLevel: PoolLevel;
  onActionSuccess?: () => void;
  isDetailsView?: boolean;
  allLevels: PoolLevel[];
};

const APRandDurationWrapper = styled.div<{ isDetailsView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;

  @media ${device.tablet} {
    gap: 14.43px;
  }

  @media ${device.laptop} {
    flex-direction: ${({ isDetailsView }) =>
      isDetailsView ? "column" : "row"};
    gap: ${({ isDetailsView }) => (isDetailsView ? "14.43px" : "37px")};
  }

  @media ${device.laptopM} {
    flex-direction: column;
    gap: 14.43px;
  }
`;

const RewardsWrapper = styled.div<{ isDetailsView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;

  @media ${device.tablet} {
    padding-right: 36px;
    gap: 18px;
  }

  @media ${device.laptop} {
    max-width: ${({ isDetailsView }) => (isDetailsView ? "unset" : "230px")};
    padding-right: 0;
    margin-top: ${({ isDetailsView }) => (isDetailsView ? "0" : "-45px")};
  }

  @media ${device.laptopM} {
    margin-top: 0;
    max-width: unset;
    gap: 18px;
  }
`;

const APRWrapper = styled.div<{ isDetailsView?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10.38px;
  }

  @media ${device.laptop} {
    flex-direction: ${({ isDetailsView }) =>
      isDetailsView ? "column" : "row"};
    justify-content: ${({ isDetailsView }) =>
      isDetailsView ? "flex-start" : "space-between"};
    align-items: ${({ isDetailsView }) =>
      isDetailsView ? "flex-start" : "center"};
  }

  @media ${device.laptopM} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const TotalAPRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
`;

const Separator = styled.p<{ isDetailsView?: boolean }>`
  color: #26a677;
  font-family: Ingra;
  font-size: 54.724px;
  font-style: normal;
  font-weight: 300;
  line-height: 93.5%; /* 51.167px */
  opacity: 0.8;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.laptop} {
    display: ${({ isDetailsView }) => (isDetailsView ? "none" : "block")};
  }

  @media ${device.laptopM} {
    display: none;
  }
`;

const DetailedAPRsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;

  @media ${device.tablet} {
    gap: 9.23px;
  }
`;

const DetailedAPR = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const DurationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 7.74px;
  }

  @media ${device.laptop} {
    gap: 4px;
    margin-top: 12px;
  }

  @media ${device.laptopM} {
    margin-top: 0;
  }
`;

const TotalAPRText = styled.p`
  color: #fff;
  font-family: Ingra;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 93.5%; /* 13.09px */
  opacity: 0.8;

  @media ${device.tablet} {
    font-size: 14.356px;
  }

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.laptopM} {
    font-size: 14.356px;
  }
`;

const APRValue = styled.p`
  color: #26a677;
  font-family: Ingra;
  font-size: 34.047px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.5%; /* 31.833px */

  @media ${device.tablet} {
    font-size: 38.967px;
  }

  @media ${device.laptop} {
    font-size: 34.047px;
  }

  @media ${device.laptopM} {
    font-size: 38.967px;
  }
`;

const APRLabel = styled.p`
  color: #afbdd7;
  font-family: Ingra;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 93.5%; /* 9.35px */
  opacity: 0.8;

  @media ${device.tablet} {
    font-size: 12.35px;
  }

  @media ${device.laptop} {
    font-size: 10px;
  }

  @media ${device.laptopM} {
    font-size: 12.35px;
  }
`;

const APRDetailedValue = styled.p`
  color: #fff;
  font-family: Ingra;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.5%; /* 14.96px */

  @media ${device.tablet} {
    font-size: 18.458px;
  }

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.laptopM} {
    font-size: 18.458px;
  }
`;

const DurationLabel = styled.p`
  color: #afbdd7;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 93.5%; /* 11.22px */
  opacity: 0.8;

  @media ${device.tablet} {
    font-size: 14.356px;
  }

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.laptopM} {
    font-size: 14.356px;
  }
`;

const DurationValue = styled.p`
  color: #fff;
  font-family: Ingra;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.5%; /* 14.96px */

  @media ${device.tablet} {
    font-size: 18.458px;
  }

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.laptopM} {
    font-size: 18.458px;
  }
`;

const DurationItemWrapper = styled.div`
  display: flex;
  gap: 3px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.tablet} {
    gap: 4.43px;
  }
`;

const MyInvestmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MyRewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RowsLabel = styled.p`
  color: #afbdd7;
  font-family: Ingra;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 93.5%; /* 13.09px */

  @media ${device.tablet} {
    font-size: 14.356px;
  }
`;

const RewardsBoostWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 7px;
  align-items: center;
  position: relative;
`;

const SmallLine = styled.img`
  display: none;

  @media ${device.tablet} {
    display: block;
    padding-top: 3px;
  }

  @media ${device.laptop} {
  }
`;

const AprAndLineWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7.67px;

  @media ${device.laptop} {
    gap: 5px;
  }

  @media ${device.laptopM} {
    gap: 7.67px;
  }
`;

const HorizontalLine = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    height: 1.025px;
    width: 100%;
    opacity: 0.2;
    background: #afbdd7;
    margin-bottom: 9px;
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.laptopM} {
    display: block;
    margin-top: 20px;
    margin-bottom: 11px;
  }
`;

const HorizontalLineSmall = styled.div<{ isDetailsView?: boolean }>`
  display: none;

  @media ${device.laptop} {
    display: ${({ isDetailsView }) => (isDetailsView ? "none" : "block")};
    height: 1.025px;
    width: 100%;
    opacity: 0.2;
    background: #afbdd7;
    margin-top: 22.17px;
  }

  @media ${device.laptopM} {
    display: none;
  }
`;

const PoolProgressWrapper = styled.div<{ isDetailsView?: boolean }>`
  display: none;
  width: 100%;
  height: 100%;

  @media ${device.laptop} {
    display: ${({ isDetailsView }) => (isDetailsView ? "none" : "block")};
    margin-top: 24.38px;
  }

  @media ${device.laptopM} {
    display: none;
  }
`;

export const PoolData = (props: InfoSectionProps) => {
  const { poolLevel, onActionSuccess, isDetailsView, allLevels } = props;
  const isMobileView = useMediaQuery(device.mobileS);
  const isTabletView = useMediaQuery(device.tablet);
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const userNotInWhitelist =
    poolLevel?.isWhitelisted && !poolLevel?.isUserOnTheWhitelist;

  const isV2Pool = poolLevel.pool.contract?.version === "v2";

  return (
    <>
      <S.InfoWrapper>
        <div style={{ width: "100%" }}>
          <APRandDurationWrapper isDetailsView={isDetailsView}>
            <APRWrapper isDetailsView={isDetailsView}>
              <TotalAPRWrapper>
                <TotalAPRText>TOTAL APR UP TO</TotalAPRText>
                {isV2Pool ? (
                  <APRValue>
                    {(Number(poolLevel.usdcApr) / 100).toFixed(2)} %
                  </APRValue>
                ) : (
                  <APRValue>
                    {(
                      (Number(poolLevel.usdcApr) +
                        Number(poolLevel.highAprLimit)) /
                      100
                    ).toFixed(2)}{" "}
                    %
                  </APRValue>
                )}
              </TotalAPRWrapper>
              <Separator isDetailsView={isDetailsView}>{`}`}</Separator>
              <DetailedAPRsWrapper>
                {isV2Pool ? null : (
                  <AprAndLineWrapper>
                    {isV2Pool ? null : (
                      <SmallLine src={smallHorizontalLine} alt="line" />
                    )}
                    <DetailedAPR>
                      <APRLabel>FIXED APR IN {config.STABLE_TOKEN}</APRLabel>
                      <APRDetailedValue>
                        {(Number(poolLevel.usdcApr) / 100).toFixed(2)} %
                      </APRDetailedValue>
                    </DetailedAPR>
                  </AprAndLineWrapper>
                )}
                {isV2Pool ? null : (
                  <AprAndLineWrapper>
                    <SmallLine src={smallHorizontalLine} alt="line" />
                    <DetailedAPR>
                      <APRLabel>
                        APR VARIABLE IN {config.PROJECT_TOKEN} UP TO
                      </APRLabel>
                      <APRDetailedValue>
                        {(Number(poolLevel.highAprLimit) / 100).toFixed(2)} %
                      </APRDetailedValue>
                    </DetailedAPR>
                  </AprAndLineWrapper>
                )}
              </DetailedAPRsWrapper>
            </APRWrapper>
            {isMobileView && !isTabletView ? (
              <ActionsWrapper>
                <PoolDeposit
                  poolLevel={poolLevel}
                  onDepositSuccess={onActionSuccess}
                />
                <PoolActions
                  allLevels={allLevels}
                  poolLevel={poolLevel}
                  onActionSuccess={onActionSuccess}
                />
              </ActionsWrapper>
            ) : null}
            <DurationWrapper>
              <DurationItemWrapper>
                <DurationLabel>DURATION</DurationLabel>
                <DurationValue>
                  {humanizePoolDuration(poolLevel.cooldown)}
                </DurationValue>
              </DurationItemWrapper>
              {isTabletView ? (
                <SmallLine
                  src={smallHorizontalLine}
                  alt="line"
                  style={{ marginBottom: "5px" }}
                />
              ) : null}
              <DurationItemWrapper>
                {isV2Pool ? (
                  <DurationLabel>POOL&apos;S VALUE</DurationLabel>
                ) : (
                  <DurationLabel>MAXIMUM POOL&apos;S VALUE</DurationLabel>
                )}

                <DurationValue>
                  {Number(
                    formatBigNumber(poolLevel.pool.hardCap, usdcTokenDecimals)
                  )
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}{" "}
                  {config.STABLE_TOKEN}
                </DurationValue>
              </DurationItemWrapper>
            </DurationWrapper>
          </APRandDurationWrapper>
          <PoolProgressWrapper isDetailsView={isDetailsView}>
            <PoolProgress
              // softCap={poolLevel.pool.softCap}
              hardCap={poolLevel.pool.hardCap}
              endTime={poolLevel.pool.end}
              balance={
                poolLevel.poolLevelBalances.find((el) => el.token === "USDC")
                  ?.balance || "0"
              }
              endOfDeposits={poolLevel.endOfDeposits}
              isWhitelistedAndUserNotInWhitelist={userNotInWhitelist}
            />
          </PoolProgressWrapper>
          <HorizontalLineSmall isDetailsView={isDetailsView} />
        </div>
        <RewardsWrapper isDetailsView={isDetailsView}>
          <MyInvestmentWrapper>
            <RowsLabel>MY INVESTMENT</RowsLabel>
            <ContentBox>
              <UsdcBar
                value={
                  poolLevel.userActiveBalance
                    ? formatBigNumber(
                        poolLevel.userActiveBalance,
                        usdcTokenDecimals
                      )
                    : 0
                }
              />
            </ContentBox>
          </MyInvestmentWrapper>
          <MyRewardsWrapper>
            <RewardsBoostWrapper>
              <RowsLabel>MY REWARDS</RowsLabel>
              <S.BoostRow as="div">
                <BoostInfo poolLevel={poolLevel} />
              </S.BoostRow>
            </RewardsBoostWrapper>
            <ContentBox>
              <UsdcBar
                value={
                  poolLevel.usdcReward
                    ? formatBigNumber(poolLevel.usdcReward, usdcTokenDecimals)
                    : 0
                }
              />
              {isV2Pool ? null : (
                <SoilBar
                  value={
                    poolLevel.soilReward
                      ? formatBigNumber(
                          poolLevel.soilReward,
                          config.SOIL_TOKEN_DECIMALS
                        )
                      : 0
                  }
                />
              )}
            </ContentBox>
          </MyRewardsWrapper>
        </RewardsWrapper>
      </S.InfoWrapper>
      {!isV2Pool && <HorizontalLine />}
    </>
  );
};
