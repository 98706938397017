import { useAppSelector } from "../redux";

interface UsdcTokenInfo {
  address: string;
  decimals: number;
}

export const useTokenData = (symbol: string): UsdcTokenInfo => {
  const chainId = useAppSelector((state) => state.ui.chainId);
  const supportedChains = useAppSelector(
    (state) => state.settings.supportedChains
  );

  const currentChain = supportedChains.find(
    (chain) => Number(chain.chainId) === chainId
  );

  const usdcToken = currentChain?.tokens.find(
    (token) => token.token === symbol
  );

  if (!currentChain || !usdcToken) {
    const polygonChain = supportedChains.find(
      (chain) => Number(chain.chainId) === 137
    );
    const defaultUsdcToken = polygonChain?.tokens.find(
      (token) => token.token === symbol
    );

    if (!defaultUsdcToken) {
      return {
        address: "0x",
        decimals: 6,
      };
    }

    return {
      address: defaultUsdcToken.address,
      decimals: defaultUsdcToken.decimals,
    };
  }

  return {
    address: usdcToken.address,
    decimals: usdcToken.decimals,
  };
};
