import BigNumber from "bignumber.js";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useAccount } from "wagmi";
import { inputStableIcon } from "../../../../assets";
import { Button, CurrencyInput, MBC } from "../../../../components";
import { useTokenData } from "../../../../hooks/useTokenData";
import { formatNumber } from "../../../../utils";
import { ConnectWalletButton } from "../../../ConnectWalletButton";
import { PoolIcon } from "../../PoolIcon";
import { useEarlyWithdrawalCheck } from "./useEarlyWithdrawalCheck";
import { usePoolWithdrawHandler } from "./usePoolWithdrawHandler";

BigNumber.config({ EXPONENTIAL_AT: 18 });

type WithdrawBodyProps = {
  poolLevel: PoolLevel;
  closeModal: () => void;
};

export const WithdrawBody = ({ poolLevel, closeModal }: WithdrawBodyProps) => {
  const { control, handleSubmit, inputValue, isWithdrawInProgress } =
    usePoolWithdrawHandler(poolLevel, closeModal);
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const { areFundsLocked, timeToUnlock } = useEarlyWithdrawalCheck(poolLevel);

  const { isConnected } = useAccount();

  const amountToGet = new BigNumber(inputValue || 0);

  const amountToGetToDisplay = amountToGet.isPositive()
    ? amountToGet
    : new BigNumber(0);

  const [isInputError, setIsInputError] = useState(false);
  const isButtonDisabled =
    isInputError ||
    amountToGet.isLessThanOrEqualTo(0) ||
    Boolean(areFundsLocked);

  return (
    <MBC.Form onSubmit={handleSubmit}>
      <MBC.LogoWrapper>
        <PoolIcon poolIcon={poolLevel.pool.icon} />
      </MBC.LogoWrapper>
      <MBC.HeaderWithLogoWrapper center>
        <MBC.InfoBox>
          <MBC.InfoRow as="li" style={{ color: "#000" }}>
            {poolLevel.name}
          </MBC.InfoRow>
        </MBC.InfoBox>
      </MBC.HeaderWithLogoWrapper>
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <CurrencyInput
            {...field}
            label="USDC"
            logo={inputStableIcon}
            decimals={usdcTokenDecimals}
            available={poolLevel.deposit}
            onErrorChange={setIsInputError}
            isDark
          />
        )}
      />
      <MBC.InfoBox>
        <MBC.InfoRow as="li" style={{ color: "#000" }}>
          You will get
          <span>{formatNumber(amountToGetToDisplay.toString(), 6)} USDC</span>
        </MBC.InfoRow>
        {areFundsLocked && (
          <MBC.InfoRow as="li" style={{ color: "#000" }}>
            Your funds are locked for {timeToUnlock}.{" "}
          </MBC.InfoRow>
        )}
      </MBC.InfoBox>
      {isConnected ? (
        <Button.Primary
          type="submit"
          bigSize
          disabled={isButtonDisabled || isWithdrawInProgress}
        >
          Withdraw
        </Button.Primary>
      ) : (
        <ConnectWalletButton />
      )}
    </MBC.Form>
  );
};
