import * as sentry from "@sentry/react";
import { AxiosError } from "axios";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAccount } from "wagmi";
import { api } from "../../../../api";
import { config } from "../../../../config";
import { useUsdcTokenAddress } from "../../../../hooks";
import { usePoolsV2ContractAddress } from "../../../../hooks/usePoolsV2ContractAddress";
import { useEarnHooks } from "../../../../pages/userPanel/Earn/EarnContextProvider/useEarnHooks";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import {
  openKycPreventModal,
  openMissingKycModal,
  openTfaModal,
} from "../../../../redux/features/uiSlice";
import {
  resetTfaAuthorizationForAction,
  setCurrentTfaAction,
} from "../../../../redux/features/userSlice";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";
import { handleBlockchainPromiseInToast } from "../../../../toasts/handleBlockchainPromiseInToast";
import { useEthersSigner } from "../../../../utils";
import { useTokenData } from "../../../../hooks/useTokenData";

type DepositFormType = {
  amount: string;
  acceptTerms: boolean;
};

export const useDepositToPoolHandler = (
  poolLevel: PoolLevel,
  onSuccess: () => void,
  closeModal: () => void,
  isV2: boolean
) => {
  const USDC_TOKEN_ADDRESS = useUsdcTokenAddress();
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");
  const [myAmount, setMyAmount] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { control, handleSubmit, watch, formState } =
    useForm<DepositFormType>();
  const signer = useEthersSigner();
  const { chainId } = useAccount();
  const dispatch = useAppDispatch();
  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isPoolsDepositAuthenticated } = tfaAuthenticatedForAction;
  const poolsV2ContractAddress = usePoolsV2ContractAddress();

  const { refreshPools } = useEarnHooks();

  const [isPending, setIsPending] = useState(false);
  const { address } = useAccount();

  const handleDeposit = async ({ amount }: DepositFormType) => {
    if (!address) {
      return;
    }

    if (!chainId) {
      return;
    }

    try {
      if (!signer) {
        throw new Error("Wallet is not connected!");
      }
      setIsPending(true);

      const parsedAmount = ethers.utils.parseUnits(amount, usdcTokenDecimals);

      const {
        data: { signature, deadline },
      } = await api.pools.getDepositSignature(
        poolLevel.pool.id,
        poolLevel.level,
        parsedAmount.toString()
      );

      let depositTx;

      if (isV2) {
        depositTx = poolsContractService.depositV2(
          USDC_TOKEN_ADDRESS,
          poolLevel.pool.poolId,
          poolLevel.level,
          parsedAmount,
          deadline,
          signature,
          signer,
          poolsV2ContractAddress,
          address
        );
      } else {
        depositTx = poolsContractService.deposit(
          USDC_TOKEN_ADDRESS,
          poolLevel.pool.poolId,
          poolLevel.level,
          parsedAmount,
          deadline,
          signature,
          signer
        );
      }

      const title = `Depositing ${amount} ${config.STABLE_TOKEN} (including fees) to ${poolLevel.name}`;

      if (depositTx) {
        await handleBlockchainPromiseInToast(
          depositTx,
          {
            pending: {
              title,
              message: "Your request is in progress...",
            },
            error: {
              title,
              message:
                "Your request has been finished unsuccessful. Try again later",
            },
            success: {
              title,
              message:
                "The transaction has been finished successful . You can check details on the block explorer by click the button below.",
            },
          },
          chainId
        );
      }

      onSuccess();
      refreshPools();
    } catch (e: unknown) {
      sentry.captureException(e);
      setIsPending(false);
      if (
        e instanceof AxiosError &&
        e.response?.data?.message === "KYC user data missing"
      ) {
        closeModal();
        return dispatch(openMissingKycModal());
      }

      if (
        e instanceof AxiosError &&
        (e.response?.data?.message === "KYC not passed." ||
          e.response?.data?.message === "KYC not found.")
      ) {
        closeModal();
        dispatch(openKycPreventModal());
      } else {
        toast.errorHandler(e, "Deposit funds failed!");
      }
      dispatch(resetTfaAuthorizationForAction());
    }
  };

  const handleDepositSubmit = (amount: string, acceptTerms: boolean) => {
    if (tfaEnabled && acceptTerms) {
      setMyAmount(amount);
      setTermsAccepted(true);
      dispatch(setCurrentTfaAction("deposit"));
      dispatch(openTfaModal());
    } else if (acceptTerms) {
      handleDeposit({ amount, acceptTerms });
    }
  };

  const submitDeposit = async ({ amount, acceptTerms }: DepositFormType) => {
    handleDepositSubmit(amount, acceptTerms);
  };

  useEffect(() => {
    if (isPoolsDepositAuthenticated) {
      handleDeposit({ amount: myAmount, acceptTerms: termsAccepted });
    }
  }, [isPoolsDepositAuthenticated]);

  return {
    control,
    handleSubmit: handleSubmit(submitDeposit),
    isPending,
    inputAmount: watch("amount"),
    formState,
  };
};
