import styled from "styled-components";
import {
  ethereumLogo,
  polygonLogo,
  arbitrumLogo,
  optimismLogo,
  baseLogo,
  bscLogo,
  avalancheLogo,
} from "../../assets";

export const SmallChainsIcons = () => (
  <IconsWrapper>
    <img src={polygonLogo} alt="Polygon" />
    <img src={ethereumLogo} alt="Ethereum" />
    <img src={arbitrumLogo} alt="Arbitrum" />
    <img src={optimismLogo} alt="Optimism" />
    <img src={baseLogo} alt="Base" />
    <img src={bscLogo} alt="BSC" />
    <img src={avalancheLogo} alt="Avalanche" />
  </IconsWrapper>
);

const IconsWrapper = styled.div`
  display: flex;
  gap: 0;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 28.74px;
    height: 28.74px;
  }

  img:not(:first-child) {
    margin-left: -7px;
  }
`;
