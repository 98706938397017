import { ethers } from "ethers";
import { useState } from "react";
import styled from "styled-components";
import { inputStableIcon } from "../../../assets";
import { Button, CurrencyInput, MBC, SmallSpinner } from "../../../components";
import { config } from "../../../config";
import { poolsContractService } from "../../../services";
import { toast } from "../../../toasts";
import { getErrorMessage, useEthersSigner } from "../../../utils";
import { useUsdcTokenAddress } from "../../../hooks";
import { usePoolsV2ContractAddress } from "../../../hooks/usePoolsV2ContractAddress";
import { useTokenData } from "../../../hooks/useTokenData";

export const SubmitButton = styled(Button.Primary)`
  width: 100%;
  margin-top: 10px;
`;

type EditUsdcLimitFormProps = {
  closeModal: () => void;
  value: string;
  update: () => Promise<void>;
  isV2?: boolean;
};

export const EditUsdcLimitForm = ({
  closeModal,
  value,
  update,
  isV2,
}: EditUsdcLimitFormProps) => {
  const signer = useEthersSigner();
  const [newLimit, setNewLimit] = useState(value.toString());
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const usdcTokenAddress = useUsdcTokenAddress();
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (signer) {
        if (isV2) {
          await poolsContractService.changeUsdcWithdrawLimitV2(
            poolsV2ContractAddress,
            signer,
            ethers.utils.parseUnits(newLimit, usdcTokenDecimals),
            usdcTokenAddress
          );
        } else {
          await poolsContractService.changeUsdcWithdrawLimit(
            signer,
            ethers.utils.parseUnits(newLimit, usdcTokenDecimals)
          );
        }
        toast.success("Updated", `Usdc withdraw limit has been updated`);
      } else {
        toast.error("Error", "User doesn't have a wallet connected");
      }
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
    }
    await update();
    closeModal();
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLimit(event.target.value);
  };

  return isLoading ? (
    <SmallSpinner />
  ) : (
    <MBC.Form onSubmit={handleSubmit}>
      <CurrencyInput
        label="USDC"
        name="USDC"
        decimals={config.SOIL_TOKEN_DECIMALS}
        logo={inputStableIcon}
        onErrorChange={setError}
        value={newLimit}
        onChange={handleValueChange}
      />
      <SubmitButton disabled={error} type="submit">
        Submit
      </SubmitButton>
    </MBC.Form>
  );
};
