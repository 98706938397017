import qs from "qs";
import { BigNumber } from "ethers";
import { apiCallWithAuthToken } from "./config";
import { GetUsersData } from "./user";

type GetRequiredTokensPerInterval = {
  soil: string;
  soilBoost: string;
  usdc: string;
};

export enum Token {
  USDC = "USDC",
}

export const getTokensPerInterval = () =>
  apiCallWithAuthToken.get<GetRequiredTokensPerInterval>(
    "pools/levels/required-tokens-per-interval"
  );

type PoolsFilteredKeys =
  | "endDate"
  | "balance"
  | "poolId"
  | "id"
  | "contract.chain.chainId";

type GetPoolsResponse = {
  data: Pool[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getPools = (params: GetQueryParams<PoolsFilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetPoolsResponse>(`/pools?${queryString}`);
};

type LevelsFilteredKeys =
  | "pool.poolId"
  | "pool.id"
  | "id"
  | "pool.start"
  | "poolId"
  | "pool.contract.chain.chainId"
  | "pool.contract.address"
  | "pool.version";

type GetLevelsResponse = {
  data: PoolLevel[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getLevels = (params: GetQueryParams<LevelsFilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetLevelsResponse>(
    `/pools/levels?${queryString}`
  );
};

export const getLevelsAsLogged = (
  params: GetQueryParams<LevelsFilteredKeys>
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetLevelsResponse>(
    `/pools/levels/as-logged?${queryString}`
  );
};

type DepositRawData = {
  amount: string;
  deadline: number;
  fee: string;
  level: string;
  nonce: BigNumber;
  poolId: string;
  userAddress: string;
};

type GetDepositSignatureResponse = {
  signature: string;
  fee: string;
  feeInUsdt: string;
  deadline: string;
  rawData: DepositRawData;
};

export const getDepositSignature = (
  poolId: Pool["id"],
  level: PoolLevel["id"],
  amount: string,
  token = Token.USDC
) =>
  apiCallWithAuthToken.get<GetDepositSignatureResponse>(
    "/pools/signature/deposit",
    {
      params: {
        poolId,
        level,
        amount,
        token,
      },
    }
  );

type UnlockTokensRawData = {
  nonce: BigNumber;
  amount: string;
  deadline: number;
  level: string;
  poolId: string;
  userAddress: string;
};

type GetUnlockSignatureResponse = {
  signature: string;
  deadline: string;
  rawData: UnlockTokensRawData;
};

export const getUnlockTokensSignature = (
  poolId: Pool["id"],
  level: PoolLevel["id"],
  tokenAmount: string,
  token = Token.USDC
) =>
  apiCallWithAuthToken.get<GetUnlockSignatureResponse>(
    "/pools/signature/unlock-tokens",
    {
      params: {
        poolId,
        level,
        tokenAmount,
        token,
      },
    }
  );

type GetClaimSignatureParams = {
  poolId: Pool["id"];
  level: PoolLevel["level"];
  rewards: string;
  soilRewards: string;
  withWithdraw: boolean;
  unlockId: number;
};

type WithdrawRawData = {
  amount: string;
  deadline: number;
  level: string;
  nonce: BigNumber;
  poolId: string;
  rewards: string;
  soilRewards: string;
  userAddress: string;
};

type GetWithdrawSignatureResponse = {
  signature: string;
  deadline: string;
  rawData: WithdrawRawData;
};

export const getWithdrawSignature = (
  poolId: string,
  level: string, // pool level id
  tokenAmount: string,
  positionId: string
) =>
  apiCallWithAuthToken.get<GetWithdrawSignatureResponse>(
    "/pools/signature/withdraw",
    {
      params: {
        poolId,
        level,
        tokenAmount,
        positionId,
      },
    }
  );

type GetRedepositSignatureParams = {
  poolId: Pool["id"];
  level: PoolLevel["level"];
  amount: string;
};

type ClaimRewardsRawData = {
  nonce: BigNumber;
  poolId: string;
  level: string;
  rewards: string;
  soilRewards: string;
  userAddress: string;
  deadline: number;
};

type GetClaimSignatureResponse = {
  signature: string;
  deadline: string;
  rawData: ClaimRewardsRawData;
};

export const getClaimRewardsSignature = (params: GetClaimSignatureParams) =>
  apiCallWithAuthToken.get<GetClaimSignatureResponse>(
    "/pools/signature/claim-rewards",
    {
      params,
    }
  );

type RedepositRawData = {
  poolId: string;
  level: string;
  rewards: string;
  userAddress: string;
  nonce: BigNumber;
  deadline: number;
};

type GetRedepositSignature = {
  signature: string;
  deadline: string;
  rawData: RedepositRawData;
};

export const getRedepositSignature = (params: GetRedepositSignatureParams) =>
  apiCallWithAuthToken.get<GetRedepositSignature>(
    "/pools/signature/redeposit",
    {
      params,
    }
  );

type PoolStatsItemBase = {
  all: string;
  V1: string;
};

type PoolStatsItemWithChainId = {
  [key: `V2_${number}`]: string;
};

export type PoolStatsItem = PoolStatsItemBase &
  Partial<PoolStatsItemWithChainId>;
interface PoolStatsResponse {
  hardCaps: PoolStatsItem;
  totalDeposited: PoolStatsItem;
  openPoolsHardCapsSums: PoolStatsItem;
}

export const getGlobalPoolStats = (chainId: number) =>
  apiCallWithAuthToken.get<PoolStatsResponse>(
    `/pools/stats?chainId=${chainId}`
  );

type PoolDataToUpdate = {
  description?: string;
  balance?: string;
  fee?: string;
};

export const updatePoolData = (poolId: number, data: PoolDataToUpdate) =>
  apiCallWithAuthToken.patch(`/pools/${poolId}`, data);

type LevelDataToUpdate = {
  name?: string;
  subtitle?: string;
  description?: string;
  aprAutoAdjust?: boolean;
  tokensPerInterval?: string;
  lowAprLimit?: string;
  highAprLimit?: string;
  aprAutoChangeValue?: string;
  boostPercentage?: string;
  boostTokensPerInterval?: string;
  apr?: string;
  cooldown?: string;
  additionalCooldown?: string;
  timeForWithdrawInSeconds?: string;
  hardCap?: number;
  rewardsCooldown?: string;
};

export const updateLevelData = (levelId: number, data: LevelDataToUpdate) =>
  apiCallWithAuthToken.patch(`/pools/levels/${levelId}`, data);

export const uploadIcon = (id: Pool["id"], formData: FormData) =>
  apiCallWithAuthToken.post(`/pools/${id}/icon`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export type UserPoolsAction = {
  createdAt: string;
  id: number;
  updatedAt: string;
  amount: string;
  balanceAfter: string;
  fee: string;
  action: string;
  txHash: string;
  txBlock: number;
  txIndex: number;
  start: string;
  end: string;
  user: GetUsersData;
};

export type UserPoolsRewards = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  soilAmount: string;
  boostAmount: string;
  start: string;
  end: string;
  user: GetUsersData;
};

export type UserPoolsClaims = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  token: string;
  soilAmount: string;
  txHash: string;
  txBlock: number;
  txIndex: string;
  user: GetUsersData;
};

type GetUserPoolsActionsResponse = {
  data: UserPoolsAction[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

type UserPoolsFilteredKeys = "id";

export const getUserPoolsActions = (
  params: GetQueryParams<UserPoolsFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserPoolsActionsResponse>(
    `/pools/user/${id}/actions?${queryString}`
  );
};

type GetUserPoolsRewardsResponse = {
  data: UserPoolsRewards[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUserPoolsRewards = (
  params: GetQueryParams<UserPoolsFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserPoolsRewardsResponse>(
    `/pools/user/${id}/rewards?${queryString}`
  );
};

type GetUserPoolsClaimsResponse = {
  data: UserPoolsClaims[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUserPoolsClaims = (
  params: GetQueryParams<UserPoolsFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserPoolsClaimsResponse>(
    `/pools/user/${id}/claims?${queryString}`
  );
};

type GetLevelsWithRewardsResponse = {
  data: Array<{
    createdAt: Date;
    id: number;
    level: 0;
    name: string;
    subtitle: string;
    description: string;
    pool: {
      createdAt: Date;
      name: string;
      poolId: number;
      id: number;
    };
    rewards: Array<{
      amount: string;
      boostAmount: string;
      createdAt: string;
      end: string;
      id: number;
      soilAmount: string;
      start: string;
      updatedAt: string;
    }>;
  }>;
  limit: number;
  page: number;
  total: number;
};

export const getLevelsWithRewards = (page: number, limit: number) =>
  apiCallWithAuthToken.get<GetLevelsWithRewardsResponse>(
    "/pools/levels/with-rewards",
    {
      params: {
        page,
        limit,
      },
    }
  );

export type RewardsHolder = {
  createdAt: string;
  id: number;
  isActive: boolean;
  txBlock: number;
  txHash: string;
  txIndex: number;
  txTime: string;
  walletAddress: string;
  updatedAt: string;
};

export type RewardsHoldersType = Array<RewardsHolder>;

type GetPoolsRewardsHoldersResponse = {
  data: RewardsHoldersType;
};

type GetRewardsHoldersFilteredKeys = "chainId" | "contract.address";

export const getPoolsRewardsHolders = (
  params: GetQueryParams<GetRewardsHoldersFilteredKeys>
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetPoolsRewardsHoldersResponse>(
    `/pools/rewards-holders?${queryString}`
  );
};

export type UnlockedShortPoolPositionItem = {
  id: number;
  createdAt: string;
  updatedAt: string;
  positionId: number;
  unlockTime: string;
  isWithdrawn: boolean;
  txBlock: number;
  txIndex: number;
  txTime: string;
  amount: string;
};

export const getUnlockedShortPoolPositions = (id: number) =>
  apiCallWithAuthToken.get<UnlockedShortPoolPositionItem[]>(
    `/pools/levels/${id}/unlocks`
  );

export type UnlockedShortPoolRewardsItem = {
  id: number;
  createdAt: string;
  updatedAt: string;
  unlockTime: string;
  amount: string;
  token: string;
  nonce: string;
};

export const getUnlockedShortPoolRewardsPositions = (id: number) =>
  apiCallWithAuthToken.get<UnlockedShortPoolRewardsItem[]>(
    `/pools/levels/${id}/rewards-unlocks`
  );

export type Chain = {
  chainId: string;
  chainSelectorCCIP: string;
  createdAt: string;
  id: number;
  isSupported: boolean;
  name: string;
  routerAddress: string;
  updatedAt: string;
};

export const getChains = () => apiCallWithAuthToken.get<Chain[]>("/chains");

type UnlockRewardsResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  token: string;
  unlockTime: string;
  nonce: string;
};

export const unlockRewards = (
  poolId: number,
  poolLevelId: number,
  amount: string
) =>
  apiCallWithAuthToken.post<UnlockRewardsResponse>("/pools/unlock-rewards", {
    poolId,
    poolLevelId,
    amount,
  });
