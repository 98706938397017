import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";

dayjs.extend(Duration);

export const humanizePoolDuration = (
  timestamp: string | number,
  unit: Duration.DurationUnitType = "seconds"
) => {
  const duration = dayjs.duration(Number(timestamp), unit).humanize();

  return duration;
};
