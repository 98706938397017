import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { CurrencyValue, InfoBox } from "../../components";
import { config } from "../../config";
import { useUsdcTokenAddress } from "../../hooks";
import { usePoolsV2ContractAddress } from "../../hooks/usePoolsV2ContractAddress";
import { poolsContractService } from "../../services";
import { toast } from "../../toasts";
import { formatBigNumber, getErrorMessage } from "../../utils";
import RpcProviderManager from "../../utils/jsonRpcProvider";
import { useTokenData } from "../../hooks/useTokenData";

export const PoolAvailableFees = () => {
  const [poolsAvailableFees, setPoolsAvailableFees] = useState<string>();
  const [poolsV2AvailableFees, setPoolsV2AvailableFees] = useState<string>();
  const [poolsAvailableFeesLoading, setPoolsAvailableFeesLoading] =
    useState(false);
  const [poolsV2AvailableFeesLoading, setPoolsV2AvailableFeesLoading] =
    useState(false);
  const { chainId } = useAccount();
  const usdcTokenAddress = useUsdcTokenAddress();
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const provider = RpcProviderManager.getInstance().getProvider(chainId);
  const isBaseNetwork = chainId === config.NETWORK_ID;
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const getFeesValue = async () => {
    setPoolsAvailableFeesLoading(true);
    try {
      const feeBalance = await poolsContractService.getFeeBalance();
      setPoolsAvailableFees(formatBigNumber(feeBalance, usdcTokenDecimals));
      setPoolsAvailableFeesLoading(false);
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
      setPoolsAvailableFeesLoading(false);
    }
  };

  const getFeesValueV2 = async () => {
    setPoolsV2AvailableFeesLoading(true);
    try {
      const feeBalance = await poolsContractService.getFeeBalanceV2(
        poolsV2ContractAddress,
        provider,
        usdcTokenAddress
      );
      setPoolsV2AvailableFees(formatBigNumber(feeBalance, usdcTokenDecimals));
      setPoolsV2AvailableFeesLoading(false);
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
      setPoolsV2AvailableFeesLoading(false);
    }
  };

  useEffect(() => {
    getFeesValue();
    getFeesValueV2();
  }, [chainId]);

  return (
    <InfoBox.Admin
      title={`Fees Available For Withdrawal ${
        isBaseNetwork ? "(Both contracts)" : ""
      }:`}
    >
      {isBaseNetwork && (
        <CurrencyValue
          value={poolsAvailableFeesLoading ? null : poolsAvailableFees || null}
          currency="USDC"
          decimals={2}
          isBig
        />
      )}
      <CurrencyValue
        value={
          poolsV2AvailableFeesLoading ? null : poolsV2AvailableFees || null
        }
        currency="USDC"
        decimals={2}
        isBig
      />
    </InfoBox.Admin>
  );
};
