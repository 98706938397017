import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { forwardRef } from "react";
import styled from "styled-components";
import { slideIcon, soilInputLogo, usdcInputLogo } from "../../assets";
import { config } from "../../config";
import { useTokenData } from "../../hooks/useTokenData";

BigNumber.config({ EXPONENTIAL_AT: 18 });

type FilledInputProps = {
  variant: "usdc" | "soil";
  withRange?: boolean;
  available: string;
  showActionWrapper?: boolean;
  fee?: string | null;
  balanceLabel?: string;
  disabled?: boolean;
  biggerPadding?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FilledInput = forwardRef<HTMLInputElement, FilledInputProps>(
  (
    {
      variant,
      withRange,
      available,
      showActionWrapper = true,
      fee,
      balanceLabel,
      disabled,
      biggerPadding,
      ...rest
    },
    ref
  ) => {
    const { value, onChange } = rest;
    const { decimals: usdcTokenDecimals } = useTokenData("USDC");
    const decimals =
      variant === "soil" ? config.SOIL_TOKEN_DECIMALS : usdcTokenDecimals;

    const availableBNs = ethers.utils.parseUnits(available, decimals);

    const percentageValue =
      (Number(value) /
        Number(ethers.utils.formatUnits(availableBNs, decimals))) *
        100 -
        20 || 0;

    const getPropertiesByVariant = (coin: string) => {
      switch (coin) {
        case "usdc":
          return {
            icon: usdcInputLogo,
            textColor: "#2775CA",
            wrapperBg: "#2775CA4D",
            inputBg: "#0000004D",
          };
        case "soil":
          return {
            icon: soilInputLogo,
            textColor: "#46BDA6",
            wrapperBg: "#46BDA64D",
            inputBg: "#0000004D",
          };

        default:
          return {
            icon: usdcInputLogo,
            textColor: "#2775CA",
            wrapperBg: "#2775CA4D",
            inputBg: "#0000004D",
          };
      }
    };

    const handleOnInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const formattedValue = Number(event.target.value)
        .toFixed(decimals)
        .replace(/\.?0+$/, "");

      if (
        new BigNumber(formattedValue).isGreaterThan(new BigNumber(available))
      ) {
        const fakeEvent = {
          target: { value: available },
        } as React.ChangeEvent<HTMLInputElement>;
        onChange?.(fakeEvent);
        return;
      }

      const fakeEvent = {
        target: { value: formattedValue },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange?.(fakeEvent);
    };

    const handleSetMax = () => {
      const formattedValue = ethers.utils.formatUnits(availableBNs, decimals);
      const fakeEvent = {
        target: { value: formattedValue },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange?.(fakeEvent);
    };

    const handleSetHalf = () => {
      const halfValue = availableBNs.div(2).toString();
      const formattedValue = ethers.utils.formatUnits(halfValue, decimals);
      const fakeEvent = {
        target: { value: formattedValue.toString() },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange?.(fakeEvent);
    };

    const handleRangeEnd = (
      event:
        | React.MouseEvent<HTMLInputElement>
        | React.TouchEvent<HTMLInputElement>
        | React.KeyboardEvent<HTMLInputElement>
    ) => {
      const target = event.target as HTMLInputElement;
      const formattedValue = Number(target.value)
        .toFixed(6)
        .replace(/\.?0+$/, "");
      const fakeEvent = {
        target: { value: formattedValue },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange?.(fakeEvent);
    };

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      let initialValue = e.target.value.replace(/,/g, ".");

      const regex = /^\d*\.?\d*$/;
      if (!regex.test(initialValue)) {
        return;
      }

      if (initialValue.startsWith(".")) {
        return;
      }

      const decimalPart = initialValue.split(".")[1];
      if (decimalPart && decimalPart.length > decimals) {
        initialValue = `${initialValue.split(".")[0]}.${decimalPart.slice(
          0,
          decimals
        )}`;
      }

      const fakeEvent = {
        target: {
          value: initialValue,
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onChange?.(fakeEvent);
    };

    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value === "0") {
        const fakeEvent = {
          target: { value: "" },
        } as React.ChangeEvent<HTMLInputElement>;
        onChange?.(fakeEvent);
      }
    };

    const handleChangeRangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const formattedValue = Number(target.value)
        .toFixed(6)
        .replace(/\.?0+$/, "");
      const fakeEvent = {
        target: { value: formattedValue },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange?.(fakeEvent);
    };

    const rangeInputStep = new BigNumber(
      ethers.utils.formatUnits(availableBNs, decimals)
    )
      .div(100)
      .toString();

    return (
      <InputAndRangeWrapper
        percentageValue={percentageValue}
        isSoilToken={variant === "soil"}
      >
        <InputAndActionsWrapper>
          <InputWrapper
            color={getPropertiesByVariant(variant).textColor}
            bgColor={getPropertiesByVariant(variant).wrapperBg}
            biggerPadding={biggerPadding}
          >
            <IconAndLabelWrapper>
              <ValueInputIcon src={getPropertiesByVariant(variant)?.icon} />
              <ValueInputLabel htmlFor="value">
                {variant === "soil"
                  ? config.PROJECT_TOKEN
                  : config.STABLE_TOKEN}
              </ValueInputLabel>
            </IconAndLabelWrapper>
            <ValueInput
              disabled={availableBNs.isZero() || disabled}
              color={getPropertiesByVariant(variant).textColor}
              bgColor={getPropertiesByVariant(variant).inputBg}
              id="value"
              type="text"
              step=".01"
              ref={ref}
              onChange={handleChangeInput}
              onFocus={handleInputFocus}
              value={value}
              placeholder="0"
              onBlur={handleOnInputBlur}
            />
          </InputWrapper>
          {showActionWrapper && (
            <ActionsAndFeeInfoWrapper>
              <ActionsWrapper>
                <BalanceWrapper>
                  <BalanceLabel>
                    {balanceLabel || "Available balance"}
                  </BalanceLabel>
                  <BalanceValue>
                    {ethers.utils.formatUnits(availableBNs, decimals)}{" "}
                    {variant === "soil"
                      ? config.PROJECT_TOKEN
                      : config.STABLE_TOKEN}
                  </BalanceValue>
                </BalanceWrapper>
                <ButtonsWrapper>
                  <TextButton
                    onClick={handleSetMax}
                    disabled={
                      value ===
                        ethers.utils.formatUnits(availableBNs, decimals) ||
                      disabled
                    }
                  >
                    MAX
                  </TextButton>
                  <TextButton
                    onClick={handleSetHalf}
                    disabled={
                      value ===
                        ethers.utils.formatUnits(
                          availableBNs.div(2),
                          decimals
                        ) || disabled
                    }
                  >
                    HALF
                  </TextButton>
                </ButtonsWrapper>
              </ActionsWrapper>
              {fee && (
                <FeeWrapper>
                  <BalanceWrapper>
                    <BalanceLabel>Protocol Fee</BalanceLabel>
                    <BalanceValue>
                      {fee} {config.STABLE_TOKEN}
                    </BalanceValue>
                  </BalanceWrapper>
                </FeeWrapper>
              )}
            </ActionsAndFeeInfoWrapper>
          )}
        </InputAndActionsWrapper>
        {withRange && (
          <RangeInput
            disabled={availableBNs.isZero() || disabled}
            type="range"
            pattern="[0-9]+([\.][0-9]{1,2})?"
            min="0"
            max={Number(ethers.utils.formatUnits(availableBNs, decimals))}
            step={rangeInputStep}
            onChange={handleChangeRangeInput}
            onMouseUp={handleRangeEnd}
            onTouchEnd={handleRangeEnd}
            onKeyUp={(e) => {
              if (e.key === "ArrowLeft" || e.key === "ArrowRight")
                handleRangeEnd(e);
            }}
            value={value && !Number.isNaN(Number(value)) ? Number(value) : 0}
          />
        )}
      </InputAndRangeWrapper>
    );
  }
);

const InputAndRangeWrapper = styled.div<{
  percentageValue: number;
  isSoilToken?: boolean;
}>`
  display: flex;
  gap: 38px;
  width: 100%;
  flex-direction: column;
  position: relative;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    border-radius: 37px;
    background: ${({ percentageValue, isSoilToken }) =>
      isSoilToken
        ? `linear-gradient(90deg, rgba(255, 255, 255, 0.57) ${
            percentageValue < 80 ? percentageValue : 80
          }%, rgba(15, 15, 15, 0.00) ${
            percentageValue < 80 ? percentageValue + 20 : 100
          }%)`
        : `linear-gradient(90deg, #2775CA ${
            percentageValue < 80 ? percentageValue : 80
          }%, rgba(15, 15, 15, 0.00) ${
            percentageValue < 80 ? percentageValue + 20 : 100
          }%)`};
    height: 21.741px;
    border: 1px solid #95959599;
  }

  /******** Firefox ********/
  input[type="range"]::-moz-range-track {
    border-radius: 37px;
    background: ${({ percentageValue, isSoilToken }) =>
      isSoilToken
        ? `linear-gradient(90deg, rgba(255, 255, 255, 0.57) ${
            percentageValue < 80 ? percentageValue : 80
          }%, rgba(15, 15, 15, 0.00) ${
            percentageValue < 80 ? percentageValue + 20 : 100
          }%)`
        : `linear-gradient(90deg, #2775CA ${
            percentageValue < 80 ? percentageValue : 80
          }%, rgba(15, 15, 15, 0.00) ${
            percentageValue < 80 ? percentageValue + 20 : 100
          }%)`};
    height: 21.741px;
    border: 1px solid #95959599;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -10px; /* Centers thumb on the track */
    border-radius: 28px;
    background-color: #ffffff;
    background-image: url(${() => slideIcon});
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    height: 38px;
    width: 72px;
    position: relative;

    &&::before {
      content: "1";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input[type="range"]::-moz-range-thumb {
    border: none;
    border-radius: 28px;
    background-color: #ffffff;
    height: 38px;
    width: 72px;
  }
`;

const InputAndActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const InputWrapper = styled.div<{
  color: string;
  bgColor: string;
  biggerPadding?: boolean;
}>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ biggerPadding }) =>
    biggerPadding ? "18px" : "6.33px 6.37px 6.33px 10.36px"};
  display: flex;
  justify-content: space-between;
  border-radius: ${({ biggerPadding }) =>
    biggerPadding ? "44px" : "25.964px"};
  gap: 40px;
  width: 100%;
`;

const ValueInput = styled.input<{ color: string; bgColor: string }>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  outline: none;
  border: none;
  border-radius: 25.964px;
  padding: 11.47px 16.78px 13.14px 11.47px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: Ingra;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 93.5%; /* 22.44px */
  text-align: right;
  width: 50%;
`;

const IconAndLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const ValueInputIcon = styled.img``;

const ValueInputLabel = styled.label`
  font-family: Ingra;
  font-size: 25.964px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.5%; /* 24.276px */
`;

const RangeInput = styled.input`
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ActionsAndFeeInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FeeWrapper = styled.div`
  padding: 0 10px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 0 10px;
  width: 100%;
`;

const BalanceWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 6px;
`;

const BalanceLabel = styled.p`
  color: #fff;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
`;

const BalanceValue = styled.p`
  color: #b9c0ff;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

const TextButton = styled.p<{ disabled?: boolean }>`
  color: #d243a1;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 93.5%; /* 11.22px */
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
