export enum SortOrderType {
  TIME_TO_ENTER = "TIME_TO_ENTER",
  DESC = "DESC",
  ASC = "ASC",
}

export type EarnState = {
  poolsLongV1?: PoolLevel[] | null;
  poolsLongV2?: PoolLevel[] | null;
  endedPools: PoolLevel[] | null;
  poolsShort?: PoolLevel[] | null;
  firstLoaded: boolean;
  pageCount: number;
  sortOrder: SortOrderType;
  isLoadingFetchPools: boolean;
  isRefetchLoading: boolean;
};

export enum EarnAction {
  FETCH_POOLS_V1_START,
  FETCH_POOLS_V2_START,
  REFETCH_START,
  FETCH_POOLS_V1_SUCCESS,
  FETCH_POOLS_V2_SUCCESS,
  FETCH_POOLS_V1_FAILURE,
  FETCH_POOLS_V2_FAILURE,
  SORT_ORDER_CHANGE,
}

type FetchPoolsStartPayloadType = {
  type: EarnAction.FETCH_POOLS_V1_START | EarnAction.FETCH_POOLS_V2_START;
};

type RefetchPoolsStartPayloadType = {
  type: EarnAction.REFETCH_START;
};

type FetchPoolsV2SuccessPayloadType = {
  type: EarnAction.FETCH_POOLS_V2_SUCCESS;
  payload: {
    poolsLongV2: PoolLevel[];
    poolsShort: PoolLevel[];
    endedPools: PoolLevel[];
    pageCount: number;
  };
};

type FetchPoolsV1SuccessPayloadType = {
  type: EarnAction.FETCH_POOLS_V1_SUCCESS;
  payload: {
    poolsLongV1: PoolLevel[];
    endedPools: PoolLevel[];
    pageCount: number;
  };
};

type FetchPoolsV2FailurePayloadType = {
  type: EarnAction.FETCH_POOLS_V2_FAILURE;
  payload: string;
};

type FetchPoolsV1FailurePayloadType = {
  type: EarnAction.FETCH_POOLS_V1_FAILURE;
  payload: string;
};

type SortOrderChangePayloadType = {
  type: EarnAction.SORT_ORDER_CHANGE;
  payload: SortOrderType;
};

export type EarnActionType =
  | FetchPoolsStartPayloadType
  | FetchPoolsV1SuccessPayloadType
  | FetchPoolsV2SuccessPayloadType
  | FetchPoolsV1FailurePayloadType
  | FetchPoolsV2FailurePayloadType
  | RefetchPoolsStartPayloadType
  | SortOrderChangePayloadType;

export const EarnReducer = (state: EarnState, action: EarnActionType) => {
  switch (action.type) {
    case EarnAction.FETCH_POOLS_V1_START: {
      return { ...state, isLoadingFetchPools: true };
    }
    case EarnAction.FETCH_POOLS_V2_START: {
      return { ...state, isLoadingFetchPools: true };
    }
    case EarnAction.REFETCH_START: {
      return { ...state, isRefetchLoading: true };
    }
    case EarnAction.FETCH_POOLS_V2_SUCCESS: {
      return {
        ...state,
        isLoadingFetchPools: false,
        isRefetchLoading: false,
        poolsLongV2: action.payload.poolsLongV2,
        poolsShort: action.payload.poolsShort,
        endedPools: action.payload.endedPools || null,
        pageCount: action.payload.pageCount,
        firstLoaded: false,
      };
    }
    case EarnAction.FETCH_POOLS_V1_SUCCESS: {
      return {
        ...state,
        isLoadingFetchPools: false,
        poolsLongV1: action.payload.poolsLongV1,
        endedPools: action.payload.endedPools || null,
        pageCount: action.payload.pageCount,
        firstLoaded: false,
      };
    }
    case EarnAction.FETCH_POOLS_V1_FAILURE: {
      return {
        ...state,
        isLoadingFetchPools: false,
      };
    }
    case EarnAction.FETCH_POOLS_V2_FAILURE: {
      return {
        ...state,
        isLoadingFetchPools: false,
        isRefetchLoading: false,
      };
    }
    case EarnAction.SORT_ORDER_CHANGE: {
      return {
        ...state,
        sortOrder: action.payload,
      };
    }
    default: {
      throw Error("Unknown action in Earn Reducer");
    }
  }
};

export const EarnInitState: EarnState = {
  poolsLongV1: null,
  poolsLongV2: null,
  poolsShort: null,
  endedPools: null,
  isRefetchLoading: false,
  firstLoaded: true,
  pageCount: 0,
  sortOrder: SortOrderType.TIME_TO_ENTER,
  isLoadingFetchPools: false,
};
