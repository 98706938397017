import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { poolsContractService } from "../../services";

interface IPoolState {
  poolsContractFee: string | null;
  poolsContractFeeFormatted: string | null;
  isError: boolean;
  poolsContractFeeV2: string | null;
  poolsContractFeeV2Formatted: string | null;
  isErrorV2: boolean;
  highestShortAPR: string | null;
  highestLongAPR: string | null;
}

const initialPoolState: IPoolState = {
  poolsContractFee: null,
  poolsContractFeeFormatted: null,
  isError: false,
  poolsContractFeeV2: null,
  poolsContractFeeV2Formatted: null,
  isErrorV2: false,
  highestShortAPR: null,
  highestLongAPR: null,
};

const getPoolsContractFee = createAsyncThunk(
  "pool/getFee",
  async (decimals: number) => {
    const fee = await poolsContractService.getPoolContractFees();

    return {
      fee: fee.toString(),
      formatted: ethers.utils.formatUnits(fee, decimals),
    };
  }
);

type GetPoolsContractFeeV2Payload = {
  poolsContractAddress: string;
  provider: ethers.providers.JsonRpcProvider;
  token: string;
  decimals: number;
};

const getPoolsContractFeeV2 = createAsyncThunk(
  "pool/getFeeV2",
  async (data: GetPoolsContractFeeV2Payload) => {
    const { poolsContractAddress, provider, token, decimals } = data;
    const fee = await poolsContractService.getPoolContractFeesV2(
      poolsContractAddress,
      provider,
      token
    );

    return {
      fee: fee.toString(),
      formatted: ethers.utils.formatUnits(fee, decimals),
    };
  }
);

const setHighestShortAPR = createAction<string>("pool/setHighestShortAPR");

const setHighestLongAPR = createAction<string>("pool/setHighestLongAPR");

export const poolSlice = createSlice({
  name: "pool",
  initialState: initialPoolState,
  reducers: {
    setHighestShortAPR: (state, action) => {
      state.highestShortAPR = action.payload;
    },
    setHighestLongAPR: (state, action) => {
      state.highestLongAPR = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPoolsContractFee.pending, (state) => {
      state.isError = false;
    });
    builder.addCase(getPoolsContractFee.fulfilled, (state, { payload }) => {
      state.poolsContractFee = payload.fee;
      state.poolsContractFeeFormatted = payload.formatted;
    });
    builder.addCase(getPoolsContractFee.rejected, (state) => {
      state.poolsContractFee = null;
      state.poolsContractFeeFormatted = null;
      state.isError = true;
    });
    builder.addCase(getPoolsContractFeeV2.pending, (state) => {
      state.isErrorV2 = false;
    });
    builder.addCase(getPoolsContractFeeV2.fulfilled, (state, { payload }) => {
      state.poolsContractFeeV2 = payload.fee;
      state.poolsContractFeeV2Formatted = payload.formatted;
    });
    builder.addCase(getPoolsContractFeeV2.rejected, (state) => {
      state.poolsContractFeeV2 = null;
      state.poolsContractFeeV2Formatted = null;
      state.isErrorV2 = true;
    });
  },
});

export const pool = {
  getPoolsContractFee,
  getPoolsContractFeeV2,
  setHighestShortAPR,
  setHighestLongAPR,
};

export default poolSlice.reducer;
