import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { Button, Modal } from "../../../components";
import { useBoolean } from "../../../hooks";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { DepositModalBody } from "./DepositModalBody";

BigNumber.config({ EXPONENTIAL_AT: 18 });

type PoolDepositProps = {
  poolLevel: PoolLevel;
  onDepositSuccess?: () => void;
};

export const PoolDeposit = ({
  poolLevel,
  onDepositSuccess,
}: PoolDepositProps) => {
  const [isModalVisible, openModal, closeModal] = useBoolean();
  const dispatch = useAppDispatch();
  const { walletAddress } = useAppSelector((state) => state.user.userData);
  const { chainId } = useAccount();
  const isPoolEnded = dayjs().isAfter(poolLevel.pool.end);
  const isDepositEnded = dayjs().isAfter(poolLevel.endOfDeposits);
  const isReachedHardCap = new BigNumber(
    poolLevel.poolLevelBalances.find((el) => el.token === "USDC")?.balance ||
      "0"
  ).isGreaterThanOrEqualTo(new BigNumber(poolLevel.pool.hardCap));

  const userNotInWhitelist =
    poolLevel?.isWhitelisted && !poolLevel?.isUserOnTheWhitelist;

  const handleDepositSuccess = () => {
    closeModal();
    if (onDepositSuccess) {
      onDepositSuccess();
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  useEffect(() => {
    if (!chainId) return;
    if (isModalVisible) {
      dispatch(
        actions.wallet.getBalances({
          address: walletAddress,
          chainId,
        })
      );
    }
  }, [isModalVisible]);

  return (
    <>
      <Modal
        title={["Loan", "terms"]}
        isOpen={isModalVisible}
        onClose={closeModal}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <DepositModalBody
          poolLevel={poolLevel}
          onDepositSuccess={handleDepositSuccess}
          closeModal={closeModal}
        />
      </Modal>
      <Button.Primary
        style={{ padding: "0 24px" }}
        onClick={(e) => handleOpenModal(e)}
        disabled={
          isPoolEnded ||
          isDepositEnded ||
          isReachedHardCap ||
          userNotInWhitelist
        }
      >
        Earn
      </Button.Primary>
    </>
  );
};
