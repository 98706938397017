import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import {
  CurrencyValue,
  InfoBox,
  Modal,
  PenIcon,
  Typography,
} from "../../components";
import { config } from "../../config";
import { useModal, useUsdcTokenAddress } from "../../hooks";
import { usePoolsV2ContractAddress } from "../../hooks/usePoolsV2ContractAddress";
import { poolsContractService } from "../../services";
import { toast } from "../../toasts";
import { formatBigNumber, getErrorMessage } from "../../utils";
import RpcProviderManager from "../../utils/jsonRpcProvider";
import { EditPoolFees } from "./EditPoolFees";
import * as S from "./PoolFees.styled";
import { useTokenData } from "../../hooks/useTokenData";

export const PoolFees = () => {
  const [poolFees, setPoolsFees] = useState<string>();
  const [poolV2Fees, setPoolV2Fees] = useState<string>();
  const [poolFeesIsLoading, setPoolFeesIsLoading] = useState<boolean>(false);
  const [poolV2FeesIsLoading, setPoolV2FeesIsLoading] =
    useState<boolean>(false);
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal();
  const {
    isModalOpen: isV2ModalOpen,
    handleModalClose: handleModalCloseV2,
    handleModalOpen: handleModalOpenV2,
  } = useModal();
  const usdcTokenAddress = useUsdcTokenAddress();
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const { chainId } = useAccount();
  const provider = RpcProviderManager.getInstance().getProvider(chainId);
  const isBaseNetwork = chainId === config.NETWORK_ID;
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const getPoolFees = async () => {
    setPoolFeesIsLoading(true);
    try {
      const poolContractFees = await poolsContractService.getPoolContractFees();
      setPoolsFees(formatBigNumber(poolContractFees, usdcTokenDecimals));
      setPoolFeesIsLoading(false);
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
      setPoolFeesIsLoading(false);
    }
  };

  const getPoolV2Fees = async () => {
    setPoolV2FeesIsLoading(true);
    try {
      const poolContractFees = await poolsContractService.getPoolContractFeesV2(
        poolsV2ContractAddress,
        provider,
        usdcTokenAddress
      );
      setPoolV2Fees(formatBigNumber(poolContractFees, usdcTokenDecimals));
      setPoolV2FeesIsLoading(false);
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
      setPoolV2FeesIsLoading(false);
    }
  };

  useEffect(() => {
    getPoolFees();
    getPoolV2Fees();
  }, [chainId]);

  return (
    <>
      <Modal
        title={["Fee on the", "pools contract V1"]}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      >
        <EditPoolFees
          closeModal={handleModalClose}
          value={poolFees || "0"}
          update={getPoolFees}
        />
      </Modal>
      <Modal
        title={["Fee on the", "pools contract V2"]}
        isOpen={isV2ModalOpen}
        onClose={handleModalCloseV2}
      >
        <EditPoolFees
          closeModal={handleModalCloseV2}
          value={poolV2Fees || "0"}
          update={getPoolV2Fees}
          isV2
        />
      </Modal>
      <InfoBox.Admin title="Fee on the pools contracts">
        {isBaseNetwork && (
          <S.Row>
            <Typography.Balance>V1</Typography.Balance>
            <CurrencyValue
              value={poolFeesIsLoading ? null : poolFees || null}
              currency="USDC"
              decimals={2}
              isBig
            />
            <PenIcon noWrap click={handleModalOpen} />
          </S.Row>
        )}
        <S.Row>
          <Typography.Balance>V2</Typography.Balance>
          <CurrencyValue
            value={poolV2FeesIsLoading ? null : poolV2Fees || null}
            currency="USDC"
            decimals={2}
            isBig
          />
          <PenIcon noWrap click={handleModalOpenV2} />
        </S.Row>
      </InfoBox.Admin>
    </>
  );
};
