import { ethers } from "ethers";
import React, { useState } from "react";
import { inputStableIcon } from "../../../assets";
import { CurrencyInput, MBC, SmallSpinner } from "../../../components";
import { useUsdcTokenAddress } from "../../../hooks";
import { usePoolsV2ContractAddress } from "../../../hooks/usePoolsV2ContractAddress";
import { useTokenData } from "../../../hooks/useTokenData";
import { poolsContractService } from "../../../services";
import { toast } from "../../../toasts";
import { getErrorMessage, useEthersSigner } from "../../../utils";
import * as S from "./EditPoolFees.styled";

type EditPoolFeesProps = {
  closeModal: () => void;
  value: string;
  update: () => Promise<void>;
  isV2?: boolean;
};

export const EditPoolFees = ({
  closeModal,
  value,
  update,
  isV2,
}: EditPoolFeesProps) => {
  const signer = useEthersSigner();
  const [poolFees, setPoolFees] = useState(value.toString());
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const usdcTokenAddress = useUsdcTokenAddress();
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (signer) {
        if (isV2) {
          await poolsContractService.changePoolsContractFeeV2(
            poolsV2ContractAddress,
            ethers.utils.parseUnits(poolFees, usdcTokenDecimals),
            signer,
            usdcTokenAddress
          );
        } else {
          await poolsContractService.changePoolsContractFee(
            ethers.utils.parseUnits(poolFees, usdcTokenDecimals),
            signer
          );
        }
        toast.success("Updated", `Pools fee has been updated`);
      } else {
        toast.error("Error", "User doesn't have a wallet connected");
      }
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
    }
    await update();
    closeModal();
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPoolFees(event.target.value);
  };

  return isLoading ? (
    <SmallSpinner />
  ) : (
    <MBC.Form onSubmit={handleSubmit}>
      <CurrencyInput
        label="USDC"
        name="SOIL"
        decimals={usdcTokenDecimals}
        logo={inputStableIcon}
        onErrorChange={setError}
        value={poolFees}
        onChange={handleValueChange}
      />
      <S.SubmitButton disabled={error} type="submit">
        Submit
      </S.SubmitButton>
    </MBC.Form>
  );
};
